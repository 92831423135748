import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageCarousel from "../components/img/ImageCarousel"
import LogoStream from "../components/img/LogoStream"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import PubSearch from "../components/PubMap/Search"

const IndexPage = () => {
  const images = [
    {
      src: "/banners/greeting-cards-banner-with-cta.jpg",
      alt: "Greeting Cards Banner",
      link: "/gift-cards/",
    },
    {
      src: "/banners/home-banner.jpg",
      alt: "Find a venue near you",
      link: "/national-search/",
    },
    {
      src: "/banners/quiz-finder.png",
      alt: "Find the perfect gift",
      link: "/gift-finder-tool/",
    },
  ]

  return (
    <Layout>
      <Seo
        title={`The Dining Out Gift Card | Valid in over 1600 Restaurants, Pubs & Bars`}
        description={`Valid in restaurants across the UK including Miller & Carter, Harvester, Toby Carvery & more. The perfect gift for birthdays, thank yous, rewards and gifts.`}
      />

      <ImageCarousel images={images} />

      <LogoStream />

      <div className="content-wrapper">
        <div className="main-with-sidebar">
          <h1>Your dining out gift card discovery starts here</h1>

          <h2>Restaurant, Pub, Bar and Hotel Gift Cards.</h2>

          <p>
            Made for spending at some of Britain's best-loved restaurants, pubs,
            bars and hotels, the Dining Out Gift Card turns eating, drinking and
            even sleeping into the perfect gift. Whether you're looking to treat
            someone at a cosy country inn or a smart city bistro, with the
            Dining Out Gift Card, you'll find that great food, drinks and
            service are always on the menu.
          </p>
          <p>
            With over 1,600 restaurants to choose from, including Miller &
            Carter, Toby Carvery, All Bar One and Harvester, you're sure to find
            something you like.{" "}
            <Link to="/ourvenues/">
              Click here to view our venues and find your nearest.
            </Link>
          </p>

          <h2>
            Check out our latest posts on{" "}
            <img
              className="inline"
              src="/general/instagram.png"
              alt="Instagram"
            />
          </h2>

          <iframe
            src="https://lightwidget.com/widgets/910d9fdb212951d6b1c5118c3c6218a4.html"
            title="Instagram Feed"
            allowtransparency="true"
            className="lightwidget-widget"
            scrolling="no"
            style={{
              width: "100%",
              border: "0px",
              height: "236px",
              overflow: "hidden",
            }}
          ></iframe>
        </div>

        <div className="sidebar">
          <div className="box">
            <a
              href="https://diningout.cashstar.com/"
              rel="noreferrer"
              target="_blank"
              className="w-full"
            >
              <StaticImage
                src="../../static/boxes/buy-now-2.png"
                alt="Dining Out Card"
                className="w-full"
              />
            </a>
          </div>
          <div className="box">
            <a
              href="https://diningout-biz.cashstar.com/"
              rel="noreferrer"
              target="_blank"
              className="w-full"
            >
              <StaticImage
                src="../../static/boxes/corporate-gift-cards.png"
                alt="Corporate Gifts"
                className="w-full"
              />
            </a>
          </div>
          <div className="box">
            <Link to="/gift-cards/" className="w-full">
              <StaticImage
                src="../../static/boxes/choose-perfect-greeting.png"
                alt="Choose the perfect greeting"
                className="w-full"
              />
            </Link>
          </div>
          <div className="box">
            <a
              href="https://diningout.cashstar.com/"
              rel="noreferrer"
              target="_blank"
              className="w-full"
            >
              <StaticImage
                src="../../static/boxes/buy-now-1.png"
                alt="Buy a gift card"
                className="w-full"
              />
            </a>
          </div>
        </div>
      </div>

      <PubSearch />
    </Layout>
  )
}

export default IndexPage
